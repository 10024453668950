import React from "react";
import Link from "gatsby-link";

import Logo from "../images/uea-logo-black_magenta.png";

export default class FourZeroFour extends React.Component {
  render() {
    return (
      <div className="four-zero-four h-screen w-screen flex flex-col justify-center items-center mx-auto px-4">
        <img
          src={Logo}
          alt="University Logo"
          style={{ maxWidth: "200px", marginBottom: "1.8rem" }}
        />
        <h1>Oops, 404: It doesn't look like we can find the page you are looking for!</h1>
        <Link to="/" style={{ borderBottom: "solid 3px #e40087" }}>
          Return home
        </Link>
      </div>
    );
  }
}
